import clsx from 'clsx';
import { BsFillPlusCircleFill } from 'react-icons/bs';

import Menu from 'app/components/Menu';

import Image from './Image';
import Text from './Text';
import Title from './Title';
import Buttons from './Buttons';
import Reference from './Reference';

import {
  IMAGE_TOP,
  IMAGE_RIGHT,
  buttonOptions,
  defaultButtonValues,
} from 'app/pages/SEOStatic/utils';

const Advertisement = ({ handleChange, value = {}, name, isSingleColumn }) => {
  const { image, title, description, reference, isOffline } = value;

  return (
    <div
      className={clsx('advertisement', {
        'image-top': value.variant === IMAGE_TOP,
        rowReverse: value.variant === IMAGE_RIGHT,
        flex: isSingleColumn && value.variant !== IMAGE_TOP,
      })}
    >
      <div className="full-width">
        <Image
          name="image"
          value={image}
          withButtonsBlock={false}
          handleChange={image => handleChange({ [name]: image })}
        />
      </div>
      <div className="full-width">
        <Title value={title} handleChange={value => handleChange({ [name]: value })} name="title" />
        <Text
          value={description}
          name="description"
          handleChange={value => handleChange({ [name]: value })}
        />
        <Reference
          name="reference"
          value={reference}
          isOffline={isOffline}
          handleChange={value => handleChange({ [name]: value })}
        />

        {value.button ? (
          <Buttons
            isAdvertisement
            name="button"
            value={value.button}
            isSingleColumn={isSingleColumn}
            handleChange={value => handleChange({ [name]: value })}
            onDelete={() => handleChange({ [name]: { button: null } })}
          />
        ) : (
          <div className="menu-block">
            <Menu
              handleClick={({ type }) =>
                handleChange({
                  [name]: { button: { ...defaultButtonValues, variant: type } },
                })
              }
              trigger={<BsFillPlusCircleFill size={30} color="#28C0A3" />}
              menu={buttonOptions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Advertisement;
