import { useState, memo, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { RiCloseLine } from 'react-icons/ri';
import { Box, Typography } from '@material-ui/core';

import uuid from 'utils/uuid';

import { useStyle } from './styles';
import UploadedImage from './UploadedImage';

const fileRenderMapping = {
  image: UploadedImage,
};

const FileUploader = ({
  style,
  onChange,
  onDelete,
  showContent,
  needToReset,
  showFileInfo,
  setNeedToReset,
  type = 'image',
  width = '500px',
  height = '60px',
  accept = 'image/png, image/jpeg',
  placeholder = (
    <>
      Drop photo here to search or <u>Browses Photo</u>
    </>
  ),
}) => {
  const classes = useStyle({ width });
  const [files, setFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const stopDefaults = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = event => {
    const file = event.target.files[0];

    if (file) {
      setFiles(prev => {
        const result = [...prev, { id: uuid(), file }];

        onChange(result);

        return result;
      });
    }

    event.target.value = null;
  };

  const handleDelete = useCallback(
    id => e => {
      stopDefaults(e);
      setFiles(prev => prev.filter(file => file.id !== id));
      onDelete?.();
    },
    [onDelete],
  );

  const handleDragEnter = e => {
    stopDefaults(e);
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    stopDefaults(e);
    setIsDragOver(false);
  };

  const handleDrop = e => {
    stopDefaults(e);
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setFiles(prev => {
        const result = [...prev, { id: uuid(), file }];

        onChange(result);

        return result;
      });
    }
  };

  useEffect(() => {
    if (needToReset) {
      setFiles([]);
      setNeedToReset(false);
    }
  }, [needToReset, setNeedToReset]);

  const uniqueId = uuid();

  return (
    <div className={classes.root} style={style}>
      {showContent && !!files.length && (
        <div className="imageContainer">
          {files.map(file => {
            const Component = fileRenderMapping[type];

            return <Component key={file.id} file={file} handleDelete={handleDelete} />;
          })}
        </div>
      )}
      <div>
        <input
          type="file"
          accept={accept}
          onChange={handleChange}
          className={classes.hidden}
          id={`file-upload-${uniqueId}`}
        />
        <label
          onDrop={handleDrop}
          onDragOver={stopDefaults}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}
          htmlFor={`file-upload-${uniqueId}`}
          className={clsx(classes.label, isDragOver && classes.onDragOver)}
        >
          <Box width={width} height={height}>
            {showFileInfo && !!files.length ? (
              <Box display="flex" height={height} width={width}>
                {files.map(({ id, file: { name, type } }) => (
                  <div key={id} onClick={e => e.preventDefault()} className={classes.fileItem}>
                    <div>{name}</div>
                    <div>({type})</div>
                    <div className="close-icon" onClick={handleDelete(id)}>
                      <RiCloseLine color="#fff" size="16" />
                    </div>
                  </div>
                ))}
              </Box>
            ) : (
              <Typography height={height} width={width} className={classes.iconText}>
                {placeholder}
              </Typography>
            )}
          </Box>
        </label>
      </div>
    </div>
  );
};

export default memo(FileUploader);
