import { useCallback, useEffect, useState, memo } from 'react';

import {
  LABEL,
  FILTERS,
  LOCATION,
  getFilters,
  categoryOption,
  adDisplayOptions,
  getSelectOptions,
  singleSelectOptions,
  contentPositionOptions,
} from '../utils';

import { useLinkAndFilterStyles } from './styles';
import { Select, InputField } from './components';

const LinkAndFilter = ({
  data,
  resetData,
  adFilters,
  isExistPath,
  urlStructure,
  handleDataChange,
}) => {
  const { root } = useLinkAndFilterStyles();
  const [needToReset, setNeedToReset] = useState(false);

  const { adSettings, filters, path } = data;
  const { isValidPath, selectedCity, category_id, label_ids } = urlStructure || {};

  useEffect(() => {
    if (needToReset) {
      resetData(['path', 'title', 'description', 'heading']);

      setNeedToReset(false);
    }
  }, [isValidPath, needToReset, resetData]);

  const handleInputChange = useCallback(
    e => {
      // TODO::: NEED TO REPLACE
      handleDataChange({ path: e.target.value.toLowerCase() });
      const timerId = setTimeout(() => {
        !needToReset && setNeedToReset(true);
        clearTimeout(timerId);
      }, 1000);
    },
    [handleDataChange, needToReset],
  );

  const handleCategoryChange = useCallback(
    e => {
      const isPrimaryFilterLabel = filters.primary?.label === LABEL;
      const isSecondaryFilterLabel = filters.secondary?.label === LABEL;

      if (isPrimaryFilterLabel || isSecondaryFilterLabel) {
        handleDataChange({
          filters: {
            [isPrimaryFilterLabel ? 'primary' : 'secondary']: { label: 'label', value: undefined },
          },
        });
      }

      handleDataChange({ filters: { category: { value: e.target.value } } });
    },
    [filters.primary?.label, filters.secondary?.label, handleDataChange],
  );

  const selectedCategory = adFilters.categories?.find(
    ({ name, id }) => name === data.filters.category?.value || id === category_id,
  );

  const selectedLabel = selectedCategory?.sections.find(({ id }) => id === label_ids?.[0]);

  const labelInPath =
    path.split('/')[1] &&
    path.split('/')[1] === selectedLabel?.slug &&
    selectedCategory &&
    isValidPath;

  const locationInPath =
    path.split('/').find(part => part === selectedCity?.toLowerCase()) && isValidPath;
  const categoryInPath =
    path.split('/').find(part => part === selectedCategory?.slug) && isValidPath;

  return (
    <div className={root}>
      <div>
        <InputField
          title="Landing page URL"
          value={path}
          boxTitle="https://www.sexjobs.nl/"
          handleChange={handleInputChange}
          {...(isExistPath && { style: { marginBottom: '5px' } })}
        />
        {isExistPath && <div className="error">Url already exist</div>}

        <div className="level-group">
          <div className="flex">
            <Select
              label="Ads"
              value={adSettings?.adsPreference}
              options={adDisplayOptions}
              handleChange={e =>
                handleDataChange({ adSettings: { adsPreference: e.target.value } })
              }
            />

            {adSettings?.adsPreference === 'Show Ads' ? (
              <Select
                className="m-20"
                value={adSettings?.position}
                options={contentPositionOptions}
                handleChange={e => handleDataChange({ adSettings: { position: e.target.value } })}
              />
            ) : (
              <div className="full-width m-20" />
            )}
            <div className="full-width" />
          </div>
        </div>
        <div>
          <div className="flex">
            <Select
              disabled
              label="Level 1"
              options={categoryOption}
              value={filters.category?.label}
            />
            <Select
              disabled={isValidPath}
              type={FILTERS}
              className="m-20"
              label="Level 2"
              options={getFilters(filters, 'secondary', adFilters)}
              value={labelInPath ? 'label' : filters.primary?.label}
              handleChange={e =>
                handleDataChange({
                  filters: { primary: { label: e.target.value, value: undefined } },
                })
              }
            />
            <Select
              disabled={isValidPath}
              type={FILTERS}
              label="Level 3"
              options={getFilters(filters, 'primary', adFilters)}
              value={locationInPath ? 'location' : filters.secondary?.label}
              handleChange={e =>
                handleDataChange({
                  filters: { secondary: { label: e.target.value, value: undefined } },
                })
              }
            />
          </div>
          <div className="flex">
            <Select
              disabled={categoryInPath}
              options={adFilters.categories}
              handleChange={handleCategoryChange}
              value={categoryInPath ? selectedCategory.name : filters.category?.value}
            />
            <Select
              className="m-20"
              disabled={!adFilters[filters.primary?.label]}
              multiple={!singleSelectOptions.includes(filters.primary?.label)}
              handleChange={e =>
                handleDataChange({ filters: { primary: { value: e.target.value } } })
              }
              options={
                labelInPath
                  ? getSelectOptions(selectedCategory.sections)
                  : adFilters[filters.primary?.label]
              }
              value={labelInPath ? [selectedLabel.name] : filters.primary?.value}
            />
            <Select
              disabled={!adFilters[filters.secondary?.label]}
              multiple={!singleSelectOptions.includes(filters.secondary?.label)}
              handleChange={e =>
                handleDataChange({ filters: { secondary: { value: e.target.value } } })
              }
              options={adFilters[filters.secondary?.label]}
              value={locationInPath ? [selectedCity] : filters.secondary?.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LinkAndFilter);
