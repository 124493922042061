import { memo } from 'react';
import Button from '@material-ui/core/Button';
import { BsFillPlusCircleFill } from 'react-icons/bs';

import {
  contentOptions,
  ADVERTISEMENT,
  defaultContentBlockValues,
  DIVIDER,
} from 'app/pages/SEOStatic/utils';
import uuid from 'utils/uuid';
import isObject from 'utils/isObject';
import Menu from 'app/components/Menu';
import { isEmptyExcludingId } from 'utils/isEmpty';
import ContentBlock from 'app/components/ContentBlock';

import { Flags, Title, Text, Image, Divider, Advertisement, Buttons } from './components';

const contentItems = {
  text: Text,
  image: Image,
  title: Title,
  description: Text,
  divider: Divider,
  button: Buttons,
  advertisement: Advertisement,
};

const Content = ({ activeFlag, setActiveFlag, data: { content }, handleContentChange }) => {
  const contentKey = activeFlag.split('_')[1];

  const currentContent = content[contentKey];

  return (
    <div className="page-content">
      <Flags activeFlag={activeFlag} setActiveFlag={setActiveFlag} />

      <div>
        {currentContent.map((el, columnIndex) => (
          <div
            key={`content-${el[0]?.id}-${activeFlag}`}
            style={{ display: el.length > 1 ? 'flex' : 'block', gap: 10 }}
          >
            {el.map((item, index) => {
              const Component = contentItems[item.type];

              const isSingleColumn = el.length === 1;

              return (
                <ContentBlock
                  key={item.id}
                  isSingleColumn={isSingleColumn}
                  disabledUpButton={columnIndex < 1}
                  renderContentCenter={isEmptyExcludingId(item)}
                  disabledDownButton={columnIndex === currentContent.length - 1}
                  withActions={(el.length > 1 && index === 1) || isSingleColumn}
                  onDelete={() =>
                    handleContentChange({
                      index,
                      columnIndex,
                      type: 'delete',
                      languages: [contentKey],
                    })
                  }
                  onMove={move =>
                    handleContentChange({
                      move,
                      index,
                      type: 'move',
                      columnIndex,
                      languages: [contentKey],
                    })
                  }
                >
                  {isEmptyExcludingId(item) ? (
                    <Menu
                      handleClick={({ type, variant }) =>
                        handleContentChange({
                          index,
                          columnIndex,
                          type: 'update',
                          languages: [contentKey],
                          contentBlock: {
                            type,
                            [type]: isObject(defaultContentBlockValues[type])
                              ? {
                                  ...defaultContentBlockValues[type],
                                  ...(variant && { variant }),
                                }
                              : defaultContentBlockValues[type],
                          },
                        })
                      }
                      trigger={<BsFillPlusCircleFill size={30} color="#28C0A3" />}
                      menu={
                        isSingleColumn
                          ? contentOptions
                          : contentOptions.filter(
                              ({ value }) => value !== ADVERTISEMENT && value !== DIVIDER,
                            )
                      }
                    />
                  ) : (
                    <Component
                      isContentBlock
                      name={item.type}
                      variant={item.variant}
                      value={item[item.type]}
                      isSingleColumn={isSingleColumn}
                      handleChange={contentBlock =>
                        handleContentChange({
                          languages: [contentKey],
                          index,
                          columnIndex,
                          contentBlock,
                          type: 'update',
                        })
                      }
                    />
                  )}
                </ContentBlock>
              );
            })}
          </div>
        ))}
      </div>

      <div className="button-group">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleContentChange({
              type: 'add',
              columnIndex: 0,
              languages: [contentKey],
              contentBlock: [{ id: uuid() }],
            });
          }}
        >
          Add 1 column block
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleContentChange({
              type: 'add',
              columnIndex: 0,
              languages: [contentKey],
              contentBlock: [{ id: uuid() }, { id: uuid() }],
            });
          }}
        >
          Add 2 column block
        </Button>
      </div>
    </div>
  );
};

export default memo(Content);
