import { useState, memo } from 'react';

import { Flags, InputField } from './components';

const SEOData = ({ data, activeFlag, setActiveFlag, handleDataChange }) => {
  const contentKey = activeFlag.split('_')[1];

  return (
    <div className="seo">
      <Flags activeFlag={activeFlag} setActiveFlag={setActiveFlag} />
      <InputField
        title="Title"
        value={data.title[contentKey]}
        handleChange={e => handleDataChange({ title: { [contentKey]: e.target.value } })}
      />
      <InputField
        title="Description"
        value={data.description[contentKey]}
        handleChange={e => handleDataChange({ description: { [contentKey]: e.target.value } })}
      />
      <InputField
        title="Heading"
        value={data.heading[contentKey]}
        handleChange={e => handleDataChange({ heading: { [contentKey]: e.target.value } })}
      />
    </div>
  );
};

export default memo(SEOData);
