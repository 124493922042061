import { FormControl, FormControlLabel, RadioGroup, withStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

import InputField from './InputField';
import ContentBlock from 'app/components/ContentBlock';

const GreenRadio = withStyles({
  root: {
    color: '#28C0A3',
    '&$checked': {
      color: '#28C0A3',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const nameMapping = {
  cta: 'CTA',
  primary: 'Primary',
  secondary: 'Secondary',
};

const Buttons = ({
  name,
  value,
  onDelete,
  handleChange,
  isAdvertisement,
  isSingleColumn,
  isContentBlock,
}) => {
  return (
    <ContentBlock
      onDelete={onDelete}
      showOnlyDeleteButton
      withActions={!isContentBlock}
      isSingleColumn={isSingleColumn}
      customWidth={!isAdvertisement && isSingleColumn ? 757 : 351}
    >
      <InputField
        required={false}
        title={`${nameMapping[value.variant]} button text`}
        value={value?.text || ''}
        handleChange={e => handleChange({ [name]: { text: e.target.value } })}
      />
      <InputField
        required={false}
        title={`${nameMapping[value.variant]} button URL`}
        value={value?.url || ''}
        handleChange={e => handleChange({ [name]: { url: e.target.value } })}
      />
      <FormControl component="fieldset">
        <RadioGroup
          name="radio-group"
          value={value?.target || '_self'}
          onChange={e => handleChange({ [name]: { target: e.target.value } })}
        >
          <FormControlLabel value="_self" control={<GreenRadio />} label="Open page in same tab" />
          <FormControlLabel value="_blank" control={<GreenRadio />} label="Open page in new tab" />
        </RadioGroup>
      </FormControl>
    </ContentBlock>
  );
};

export default Buttons;
