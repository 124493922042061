import { memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'underline', 'link'],
  [{ color: ['black', 'red', 'yellow', 'white', 'green', 'blue', 'gray'] }],
  ['italic'],
];

const TextEditor = ({ name, value, setValue, container = TOOLBAR_OPTIONS }) => (
  <ReactQuill
    theme="snow"
    value={value}
    modules={{ toolbar: { container } }}
    onChange={data => {
      // this is a workaround for an issue with the quill editor
      if (data === '<p><br></p>') return;
      setValue({ target: { name: name, value: data } });
    }}
  />
);

export default memo(TextEditor);
