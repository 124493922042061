import { capitalize } from '@material-ui/core';
import { BsFillPlusCircleFill } from 'react-icons/bs';

import Menu from 'app/components/Menu';
import TextEditor from 'app/components/Dictionary/components/TextEditor';

import Buttons from './Buttons';

import { buttonOptions, defaultButtonValues } from '../../utils';

const TOOLBAR_OPTIONS = [
  ['italic', 'bold', 'underline'],
  [{ color: ['black', 'red', 'yellow', 'white', 'green', 'blue', 'gray'] }],
  ['link'],
];

const Text = ({ handleChange, value, name, isSingleColumn }) => {
  const isText = name === 'text';

  return (
    <div>
      <div>{capitalize(name)}</div>
      <TextEditor
        name={name}
        container={TOOLBAR_OPTIONS}
        value={isText ? value.value : value}
        setValue={({ target }) =>
          handleChange({ [target.name]: isText ? { value: target.value } : target.value })
        }
      />
      <div
        style={{
          ...(!value.button && { display: 'flex', justifyContent: 'center' }),
          marginTop: '30px',
        }}
      >
        {isText &&
          (value.button ? (
            <Buttons
              name="button"
              value={value.button}
              isSingleColumn={isSingleColumn}
              onDelete={() => handleChange({ [name]: { button: null } })}
              handleChange={button => handleChange({ [name]: button })}
            />
          ) : (
            <Menu
              handleClick={({ type }) =>
                handleChange({
                  [name]: { button: { ...defaultButtonValues, variant: type } },
                })
              }
              trigger={<BsFillPlusCircleFill size={30} color="#28C0A3" />}
              menu={buttonOptions}
            />
          ))}
      </div>
    </div>
  );
};

export default Text;
