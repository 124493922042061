import { HttpRequest } from 'services';

export const cmsService = {
  getPages: (query = {}) => {
    const searchParams = new URLSearchParams();

    Object.entries(query).forEach(
      ([queryKey, value]) => (value || value === 0) && searchParams.append(queryKey, value),
    );

    return HttpRequest.get(
      `cms/pages${searchParams.toString() ? `?${searchParams.toString()}` : ''}`,
    );
  },
  removePage: id => {
    return HttpRequest.delete(`cms/pages/${id}`);
  },
  createPage: data => {
    return HttpRequest.post('cms/pages', data);
  },
  updatePage: (data, id) => {
    return HttpRequest.put(`cms/pages/${id}`, data);
  },
  getPage: id => {
    return HttpRequest.get(`cms/pages/${id}`);
  },
  getIsExistPath: path => {
    return HttpRequest.get(`cms/pages/check?path=${path}`);
  },
  getAdStatusByReferenceId: referenceId => {
    return HttpRequest.get(`/aggregator/advertisement/status/${referenceId}`);
  },
  validateUrl: slug => {
    return HttpRequest.post(`/cms/url/validate`, slug);
  },
};
