import options from 'config';

const getImageUrl = ({ account_id, purpose, hash, thumb = 'thumb_298', v }) =>
  `${options.IMAGE_BASE_URL}/${account_id}/${purpose}/${hash}_${thumb}.jpg?v=${v}`;

export const getCMSImageUrl = ({ purpose, hash, thumb = 'base_thumb', v }) =>
  `${options.IMAGE_BASE_URL}/${purpose}/${hash}_${thumb}.jpg?v=${v}`;

export const getOriginalImageUrl = ({ account_id, purpose, hash, token, v }) =>
  `${options.IMAGE_BASE_URL}/${account_id}/${purpose}/${hash}.jpg?token=${token}&v=${v}`;

export default getImageUrl;
