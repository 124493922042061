import { Route, Redirect } from 'react-router-dom';
import AuthLayout from 'app/_layouts/auth';
import EntryLayout from 'app/_layouts/entry';
import cookies from 'js-cookie';

export default function RouteWrapper({ component: Component, isPrivate, ...rest }) {
  const signed = !!cookies.get('authToken');
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: `${rest.location.pathname}${rest.location.search || ''}` },
        }}
      />
    );
  }
  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */

  if ((!isPrivate && signed) || rest.location.pathname === '/') {
    return <Redirect to="/dashboard" />;
  }

  // const Layout = signed ? AuthLayout : EntryLayout;

  const Layout = signed ? AuthLayout : EntryLayout;

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */

  return (
    <Route
      {...rest}
      render={props => (
        <Layout deviceType={rest.deviceType} isLandscape={rest.isLandscape}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
