import { memo } from 'react';
import clsx from 'clsx';

import { IMAGE_RIGHT, IMAGE_TOP } from 'app/pages/SEOStatic/utils';

import Image from './Image';
import Button from './Button';
import Description from './Description';
import { useAdvertisementStyles } from './styles';

const Advertisement = ({ value }) => {
  const { root, content } = useAdvertisementStyles({ variant: value.variant });

  const isImageTop = value.variant === IMAGE_TOP;
  const isImageRight = value.variant === IMAGE_RIGHT;

  return (
    <div className={clsx(root, { reverse: isImageRight })}>
      {value.image?.url && (
        <Image
          value={value.image}
          className={clsx({ imageCenter: isImageTop, mb16: value.title })}
        />
      )}
      <div className={content}>
        {value.title && (
          <h2 className={clsx({ mb16: value.description, textCenter: isImageTop })}>
            {value.title}
          </h2>
        )}
        {value.description && (
          <Description
            className={clsx({ mb16: value.button })}
            value={{ value: value.description }}
          />
        )}
        {value.button && <Button value={value.button} />}
      </div>
    </div>
  );
};

export default memo(Advertisement);
