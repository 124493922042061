import { useMemo, useState, useRef, memo } from 'react';

import InputField from './InputField';
import { useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';
import { cmsService } from 'services/cms';

const Reference = ({ handleChange, value, name, adIsOffline }) => {
  const referenceIdRef = useRef(value);
  const referenceId = useMemo(() => value, [value]);
  const [isOffline, setIsOffline] = useState(adIsOffline);
  const debouncedReferenceId = useDebounce(referenceId, 500);

  useEffect(() => {
    if (debouncedReferenceId && debouncedReferenceId !== referenceIdRef.current) {
      (async () => {
        try {
          const {
            data: { isOffline },
          } = await cmsService.getAdStatusByReferenceId(debouncedReferenceId);
          handleChange({ isOffline: isOffline });
          setIsOffline(isOffline);
        } catch (error) {
          console.error(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedReferenceId]);

  return (
    <InputField
      title="Reference"
      value={value}
      required={false}
      boxTitle={<span {...(isOffline && { style: { color: 'red' } })}>#</span>}
      handleChange={e => {
        if (typeof Number(e.target.value) === 'number' && !isNaN(e.target.value)) {
          handleChange({ [name]: e.target.value });
        }
      }}
    />
  );
};

export default memo(Reference);
