import { memo } from 'react';

import { useTitleStyles } from './styles';

const Title = ({ value }) => {
  const { title } = useTitleStyles();
  return <h1 className={title}>{value}</h1>;
};

export default memo(Title);
