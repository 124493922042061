import { useState, useEffect } from 'react';

import useDebounce from 'hooks/useDebounce';

function useDebounceCallback({ callback, value, delay = 500, defaultValue }) {
  const [state, setState] = useState(defaultValue);
  const debouncedValue = useDebounce(value, delay);

  useEffect(() => {
    if (debouncedValue) {
      (async () => {
        const { data } = await callback();

        setState(data);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return state;
}

export default useDebounceCallback;
