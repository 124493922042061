import { memo } from 'react';
import { FaTrash } from 'react-icons/fa';

import { useImageStyles } from './styles';

const UploadedImage = ({ file: { file, id }, handleDelete }) => {
  const { imageWrapper } = useImageStyles();

  return (
    <div className={imageWrapper}>
      <img src={URL.createObjectURL(file)} alt={file.name} />
      <div className="icon-wrapper" onClick={handleDelete(id)}>
        <FaTrash className="icon" color="white" size="14" />
      </div>
    </div>
  );
};

export default memo(UploadedImage);
