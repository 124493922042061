import { FaCaretRight } from 'react-icons/fa';
import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';

import Dropdown from './Dropdown';
import NestedMenuItem from './NestedMenuItem.js';

const DropdownMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between !important',

  '& > svg': {
    marginLeft: '32px',
  },
});

const Menu = ({ menu = [], handleClick, trigger }) => {
  return (
    <Dropdown
      trigger={trigger}
      menu={menu.map(({ value, label, subMenu }) => (
        <NestedMenuItem
          key={label}
          label={label}
          {...(!subMenu && { onClick: () => handleClick({ type: value }) })}
          rightIcon={subMenu && <FaCaretRight />}
          menu={subMenu?.map(item => (
            <DropdownMenuItem
              key={item.label}
              onClick={() => handleClick({ type: value, variant: item.value })}
            >
              {item.label}
            </DropdownMenuItem>
          ))}
        />
      ))}
    />
  );
};

export default Menu;
