import { memo } from 'react';

import { useDividerStyles } from './styles';

const Divider = () => {
  const { root } = useDividerStyles();
  return <div className={root} />;
};

export default memo(Divider);
