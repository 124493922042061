import { memo } from 'react';
import clsx from 'clsx';

import { useButtonStyles } from './styles';

const Button = ({ value }) => {
  const { root } = useButtonStyles();

  return (
    <a className={clsx(root, value.variant)} href={value.url} target={value.target}>
      {value.text}
    </a>
  );
};

export default memo(Button);
