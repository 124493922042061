import { makeStyles } from '@material-ui/core';

export const useImageStyles = makeStyles({
  imageWrapper: {
    position: 'relative',
    '& img': {
      height: '100%',
      borderRadius: '5px',
    },
    '& .icon-wrapper': {
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 10,
      display: 'none',
      right: 5,
      bottom: 5,
      background: '#e25d6f',
      borderRadius: '4px',
      margin: '4px',
      '& .icon': {
        margin: '4px',
      },
    },
    '&:hover img': {
      filter: 'opacity(0.7)',
    },
    '&:hover .icon-wrapper': {
      display: 'block',
    },
  },
});

export const useStyle = makeStyles({
  root: {
    width: '100%',
    '& .imageContainer': {
      display: 'flex',
      height: '140px',
      gap: '10px',
      margin: '10px 0',
    },
  },
  fileItem: {
    width: 120,
    height: 40,
    backgroundImage: 'linear-gradient(to top, #ededed, #efefef, #f1f1f1, #f4f4f4, #f6f6f6)',
    margin: '10px 5px',
    fontSize: '10px',
    color: '#717386',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 9,
    flexDirection: 'column',

    '& .close-icon': {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      height: '22px',
      width: '22px',
      top: '-5px',
      right: '-5px',
      borderRadius: '50%',
      background: '#bbb',
      color: '#333',
      cursor: 'pointer',
      zIndex: 10,
    },
    '&:hover .close-icon': {
      display: 'flex',
    },
  },
  label: {
    width: ({ width }) => width,
    border: '2px dashed #28C0A3',
    cursor: 'pointer',
    display: 'block',
    '&:hover p,&:hover svg,& img': {
      opacity: 1,
    },
    '& p, svg': {
      opacity: 0.6,
    },
    '&:hover img': {
      opacity: 0.3,
    },
  },

  iconText: {
    color: '#34bfa3',
    paddingTop: '20px',
    textAlign: 'center',
    height: '100%',
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
  onDragOver: {
    '& img': {
      opacity: 0.3,
    },
    '& p, svg': {
      opacity: 1,
    },
  },
});
