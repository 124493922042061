import { useState, useEffect, useCallback, memo, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import Portal from '@material-ui/core/Portal';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { cmsService } from 'services/cms';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { throwErrorActions } from 'store/slices/throwError';

import { ReactComponent as FaClose } from 'images/faClose.svg';

import Content from './Content';
import SEOData from './SEOData';
import Preview from './Preview';
import LinkAndFilter from './LinkAndFilter';
import { useDialogStyles } from './styles';

import {
  SHOW_ADS,
  updateState,
  DONT_SHOW_ADS,
  changeContentState,
  replaceUrlStructureData,
} from '../utils';
import { getMetadataWithLocation } from 'utils/meta';
import useMount from 'hooks/useMount';
import useDebounce from 'hooks/useDebounce';

const steps = {
  1: LinkAndFilter,
  2: SEOData,
  3: Content,
};

const stepsTitle = {
  1: 'Link&Filter',
  2: 'SEO data',
  3: 'Content',
};

const defaultState = {
  filters: {
    primary: {},
    secondary: {},
    category: { label: 'Category' },
  },
  path: '',
  adSettings: { adsPreference: '', position: '' },
  title: { en: '', nl: '' },
  description: { en: '', nl: '' },
  heading: { en: '', nl: '' },
  content: { en: [], nl: [] },
};

const LandingPageManagementDialog = ({ selectedId, adFilters, handleCloseDialog }) => {
  const [data, setData] = useState(defaultState);
  const [currentStep, setCurrentStep] = useState(1);
  const [isExistPath, setIsExistPath] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [activeFlag, setActiveFlag] = useState('value_nl');

  const dispatch = useDispatch();
  const { root, dialogTitle } = useDialogStyles();

  const debouncedPath = useDebounce(data.path, 500);

  const currentPath = useRef(null);

  const {
    path,
    title: currentTitle,
    heading: currentHeading,
    description: currentDescription,
    adSettings: { adsPreference, position } = {},
    filters: { category, primary, secondary } = {},
  } = data;

  const urlData = useDebounceCallback({
    callback: () =>
      cmsService.validateUrl({
        slug: data?.path.split('?')[0].split('/'),
      }),
    value: data?.path,
    defaultValue: {},
  });

  const urlStructure = replaceUrlStructureData(urlData);

  const Component = steps[currentStep];
  const contentKey = activeFlag.split('_')[1];

  const hasSelectedFilter = !!primary?.value || !!secondary?.value || !!category?.value;

  const hasSelectedPreferences =
    adsPreference === DONT_SHOW_ADS || (adsPreference === SHOW_ADS && position);

  const hasMissingData =
    !hasSelectedPreferences || (!hasSelectedFilter && !urlStructure?.isValidPath) || !path.trim();

  const handleDataChange = useCallback(data => {
    setData(prev => updateState(prev, data));
  }, []);

  const handleContentChange = useCallback(
    ({ languages, columnIndex, index, contentBlock, type, move }) => {
      setData(prevState => ({
        ...prevState,
        content: changeContentState({
          type,
          move,
          index,
          languages,
          columnIndex,
          contentBlock,
          content: prevState.content,
        }),
      }));
    },
    [],
  );

  const resetData = useCallback(
    excluded =>
      setData(prev => ({
        ...defaultState,
        ...(excluded &&
          excluded.reduce((acc, el) => {
            acc[el] = prev[el];
            return acc;
          }, {})),
      })),
    [],
  );

  const { heading, metaData, isValidPath } = urlStructure || {};

  const { metaTitle = '', metaDescription = '' } = getMetadataWithLocation(metaData, data?.path);

  useEffect(() => {
    if (debouncedPath && ((isEditMode && path && path !== currentPath.current) || !isEditMode)) {
      (async () => {
        try {
          const { data } = await cmsService.getIsExistPath(path);

          setIsExistPath(data.exists);
        } catch (e) {
          dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
        }
      })();
    } else if (isExistPath) {
      setIsExistPath(false);
    }
    // Don't update dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPath]);

  useMount(() => {
    if (selectedId) {
      (async () => {
        try {
          const { data } = await cmsService.getPage(selectedId);

          currentPath.current = data.path;

          setData(data);
        } catch (e) {
          dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
        }
      })();
    }
  });

  useEffect(() => {
    handleDataChange({
      title: { nl: metaTitle, en: '' },
      heading: { nl: heading?.nl, en: '' },
      description: {
        nl: metaDescription,
        en: '',
      },
    });
    // Don't update dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDescription, metaTitle, heading?.nl]);

  const isEditMode = !!selectedId;
  const title = `${isEditMode ? 'Edit' : 'Create'} Landing page`;

  const handleShowPreview = () => setShowPreview(true);

  const handleCreatePage = async () => {
    try {
      const {
        data: { result },
      } = await cmsService[isEditMode ? 'updatePage' : 'createPage'](data, selectedId);

      if (result) {
        dispatch(
          throwErrorActions.updateSuccess(
            `Page has been successfully ${isEditMode ? 'edited' : 'created'}!`,
          ),
        );
        handleCloseDialog(true);
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatch(
        throwErrorActions.updateMessage({
          message: e.response?.data.message || 'Something went wrong...',
        }),
      );
    }
  };

  return (
    <MUIDialog
      open
      maxWidth="lg"
      className={root}
      disableBackdropClick
      onClose={() => handleCloseDialog()}
    >
      <DialogTitle className={dialogTitle} id="alert-dialog-title">
        <div>
          <div className="title">{title}</div>
          <div className="close">
            <FaClose width="20px" height="20px" onClick={() => handleCloseDialog()} />
          </div>
        </div>
        <hr />
      </DialogTitle>
      <DialogContent style={{ minWidth: '860px' }}>
        <div className="first-step-title">
          <span>Step {currentStep}:</span> {stepsTitle[currentStep]}
        </div>
        <Component
          data={data}
          resetData={resetData}
          adFilters={adFilters}
          activeFlag={activeFlag}
          isEditMode={isEditMode}
          isExistPath={isExistPath}
          urlStructure={urlStructure}
          setActiveFlag={setActiveFlag}
          handleDataChange={handleDataChange}
          handleContentChange={handleContentChange}
        />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <div>
          {currentStep > 1 && (
            <Button
              className={clsx({ purple: currentStep !== 1 })}
              variant="contained"
              onClick={() =>
                currentStep === 2
                  ? handleDataChange({
                      title: { en: data.title.nl },
                      description: { en: data.description.nl },
                      heading: { en: data.heading.nl },
                    })
                  : handleShowPreview()
              }
              color="primary"
            >
              {currentStep === 2 ? 'Autocomplete' : 'Preview'}
            </Button>
          )}
          {currentStep === 3 && (
            <Button
              color="primary"
              className="yellow"
              variant="contained"
              onClick={() =>
                handleContentChange({
                  languages: ['en'],
                  type: 'autocomplete',
                })
              }
            >
              Autocomplete
            </Button>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() =>
              currentStep === 1 ? handleCloseDialog() : setCurrentStep(prev => prev - 1)
            }
            color="secondary"
          >
            {currentStep === 1 ? 'Cancel' : 'Prev step'}
          </Button>
          <Button
            disabled={isExistPath || (!isValidPath && hasMissingData)}
            variant="contained"
            onClick={() =>
              currentStep === 3 ? handleCreatePage() : setCurrentStep(prev => prev + 1)
            }
            color="primary"
          >
            {currentStep < 3 ? 'Next step' : isEditMode ? 'Save' : 'Create'}
          </Button>
        </div>
      </DialogActions>
      {showPreview && (
        <Portal>
          <Preview
            content={data.content[contentKey]}
            onClosePreview={() => setShowPreview(false)}
          />
        </Portal>
      )}
    </MUIDialog>
  );
};

export default memo(LandingPageManagementDialog);
